<template>
  <el-card class="card-item" shadow="always" body-style="{ padding: '10px' }">
    <div class="divflexyb">
      <div class="card-title divflexxb">
        <div>{{title}}</div>
        <img src="@/assets/images/logo.png" alt="" class="logo">
      </div>
      <div v-show="isMoney === false" class="card-body">{{bodyData}}</div>
      <div v-show="isMoney === true" class="card-body">{{bodyData|Money}}</div>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "Card",
  props: {
    title: { String, default: undefined },
    bodyData: { String, default: undefined },
    isMoney: { Boolean, default: false }
  }
}
</script>

<style lang="scss" scoped>
.card-box {
  margin: 20px 0;
}

.card-item {
  width: 400px;
  min-width: 250px;
  height: 160px;
}


.card-title {
  width: 100%;
  height: 40px;
  color: #56585c;
  font-size: 20px;
}

.card-body {
  color: #ff7993;
  font-size: 50px;
  text-align: center;
  margin: 20px 0;
}
.logo {
  height: 40px;
}
</style>