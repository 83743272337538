<template>
  <div class="container clearfix min-width">
    <div class="card-box divflexxb">
      <card title="油枪金额(¥)" :bodyData="cardData.gunAmount" :isMoney="true"></card>
      <card title="到账金额(¥)" :bodyData="cardData.arriveAmount" :isMoney="true"></card>
      <card title="订单总数" :bodyData="orderNo"></card>
      <card title="提现金额(¥)" :bodyData="cardData.withdrawAmount" :isMoney="true"></card>
    </div>
    <!-- 订单列表 -->
    <div class="data-box">
        <div class="table-title">最新订单</div>
        <el-table v-loading="orderListLoading" :data="orderListData" border :row-class-name="tableRowClassName" :cell-style="{fontSize:'14px'}" :header-cell-style="{background:'#f0f9eb', fontFamily:'Helvetica',fontSize:'16px',height:'50px'}" :row-style="{height: '30px'}">
          <el-table-column prop="gasName" label="加油站名" width="180" align="center" />
          <el-table-column label="下单时间" align="center" prop="createTime" width="200px" />
          <el-table-column label="订单编号" align="center" prop="tradeNo" width="220px" />
          <el-table-column label="微信昵称" align="center"  width="220px" >
            <template slot-scope="scope">
              {{scope.row.nickname?scope.row.nickname:'-'}}
            </template>
          </el-table-column>
          <el-table-column label="手机号" align="center"  width="220px" >
            <template slot-scope="scope">
              {{scope.row.phone?scope.row.phone:'-'}}
            </template>
          </el-table-column>
          <!-- <el-table-column label="油站地址" align="center" prop="gasAddress" width="550px" /> -->
          <el-table-column label="油枪号" align="center" prop="gunNum" width="150px">
            <template slot-scope="scope">
              <b>{{scope.row.gunNum +"号"}}</b>/{{scope.row.goodsName}}
            </template>
          </el-table-column>
          <el-table-column label="油枪金额" align="center" prop="orderMoney">
            <template slot-scope="scope">
              {{scope.row.orderMoney | Money}}
            </template>
          </el-table-column>
          <el-table-column label="到账金额" align="center" prop="actualMoney">
            <template slot-scope="scope">
              {{scope.row.gasMoney |Money}}
            </template>
          </el-table-column>
          <el-table-column label="已播报" align="center" prop="tradeNo" width="80px">
            <template slot-scope="scope">
              <div class="color:red;" :class="{ht:scope.row.voice===1}">{{scope.row.voice===0 ? "未播报" : "已播报"}}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" prop="tradeNo" width="80px">
            <template slot-scope="scope">
              <el-button type="text" @click="replay(scope.row)">
                <template>
                  <div class="divflexxa">
                    <i class="el-icon-video-camera-solid" style="font-size:14px"></i>
                    <div style="font-size:14px">{{scope.row.voice===0 ? "播放" : "重播"}}</div>
                    <audio  controls="controls" autoplay style="display: none" />
                  </div>
                </template>
              </el-button>
              <el-button type="text" @click="againReceipt(scope.row)">补打小票</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
  </div>
</template>

<script>

import { getOrderList, upOrderVoice, againReceipt1,getNewNoPlay, salesStatistics, getOrderCount } from "@/api/order";

import Card from '@/components/common/card.vue'
import {againReceipt} from "../../api/order";
import { voicePrompt} from "@/utils/globalFunc";

export default {
  name: "dashboard",
  components: { Card },
  data() {
    return {
      showSearch: true,
      orderListLoading: false,
      startTime: `${this.getToDay()} 00:00:00`,
      endTime: `${this.getToDay()} 23:59:59`,
      newNoPlayList: new Array,
      todyQuery: {
        pageNo: 1,
        pageSize: 10000,
        status: 2,
        startTime: `${this.getToDay()} 00:00:00`,
        endTime: `${this.getToDay()} 23:59:59`,
        gasId: JSON.parse(localStorage.getItem('userInfo')).gasId,
      },
      cardData: {},
      orderNo: 0,
      orderListData: [],
    }
  },

  created() {
    this.getNewOrderList()
    this.getSalesStatistics()
    this.getBroadcastStatus()
    this.getToDayOrderCount()
  },
  mounted() {
    this.$bus.on("updateList", (item) => {
      if (item) {
        this.getNewOrderList()
        this.getSalesStatistics()
        this.getBroadcastStatus()
        this.getToDayOrderCount()
      }
    })
  },
  beforeDestroy() {
    this.$bus.off('updateList')
  },
  methods: {
    // 自定义斑马纹
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },

    // 获取最新订单
    getNewOrderList() {
      getOrderList(this.todyQuery).then((res) => {
        this.orderListData = res.data
      })
    },

    // 获取当日销售额统计
    getSalesStatistics() {
      salesStatistics(this.todyQuery).then((res) => {
        this.cardData = res.data
      })
    },

    // 获取当前播放状态
    getBroadcastStatus() {
      this.broadcastStatus = Boolean(eval(localStorage.getItem('broadcastStatus')))
    },

    // 获取当日订单总数
    getToDayOrderCount() {
      getOrderCount(this.todyQuery).then((res) => {
        this.orderNo = res.data.orderNo
      }
      )
    },

    // 重播
    replay(row) {
      var text = `${row.gunNum}号枪,${row.goodsName.replace('#',"号")}新能加油:${(row.orderMoney / 100).toFixed(2)}元`
      voicePrompt(text)
      upOrderVoice({ gasId: JSON.parse(localStorage.getItem('userInfo')).gasId, id: row.id }).then((res) => {
        this.getNewOrderList()
      })
    },
  // 补打小票
    againReceipt(row) {
      againReceipt1({ gasId: JSON.parse(localStorage.getItem('userInfo')).gasId, id: row.id }).then((res) => {
        this.$message.success('补打小票成功')
      })
    },

  },
};
</script>

<style  scoped>
.container{
  padding-top: 0 !important;
}
.query-form .el-form-item {
  margin-bottom: -5px;
}

.table-title {
  width: 100%;
  color: #303133;
  font-size: 20px;
  margin-bottom: 20px;
}
.ht {
  color: #1fc96b;
}

/deep/ .el-table .warning-row {
  background: oldlace;
}

/deep/ .el-table .success-row {
  background: #f0f9eb;
}
.card-box{
  width: 100%;
}
.data-box{
  margin-top: 20px;
}
</style>
